<template>
  <div class="content-wrapper">
    <div class="content-body">
      <article class="body-article">
        <div class="article-left"> <!--좌측 영역-->
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section ">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{gridField.title}}</div>
                <div class="header-caption">[{{ gridField.count }}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <!--                  그리드 위에 버튼영역-->
                  <ul class="lookup-button">
                    <li class="modify">
                      <erp-button button-div="SAVE" @click.native="onClickModifyParent">수정</erp-button>
                    </li>
                    <li class="add">
                      <erp-button button-div="SAVE" @click.native="onClickAddParent">추가</erp-button>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <!--              그리드1-->
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind="initGrid"
                  @queryCellInfo  = "onQueryCellInfo"
                  @actionComplete = "gridComplete"
                  @recordClick = "onGridClick"
              />
            </div>
          </section>
        </div>
        <div class="article-right"><!--우측 영역-->
          <section class="article-section ">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{gridField2.title}}</div>
                <div class="header-caption">[{{ gridField2.count }}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <!--                  그리드 위에 버튼영역-->
                  <li class="add">
                    <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="onClickAddRow">행추가</erp-button>
                  </li>
                  <li class="delete">
                    <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="onClickDelRow">행삭제</erp-button>
                  </li>
                  <li class="save keyColor">
                    <erp-button button-div="SAVE" :ignore="isPopupOpened" :is-icon-custom="true" :is-shortcut-button="true" @click.native="onClickSave">저장</erp-button>
                  </li>
                  <li class="print">
                    <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <!--              그리드2-->
              <ejs-grid-wrapper
                  ref="grid2"
                  v-bind="initGrid2"
                  @headerCellInfo = "onHeaderCellInfo2"
                  @actionComplete = "gridComplete2"
                  @queryCellInfo  = "onQueryCellInfo2"
              />
            </div>
          </section>
        </div>
      </article>

    </div>
    <!-- 각종팝업 -->
    <work-code-registration-popup
        v-if="isPopupOpen"
        ref="codePopup"
        @popupClosed="closePopup"
        @popupSaved="savePopup"
    />
  </div>
</template>


<script>


import ErpButton from "@/components/button/ErpButton.vue";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import WorkCodeRegistrationPopup from "@/views/stock-management/equipment/popup/WorkCodeRegistrationPopup.vue";
import CommonMixin from "@/views/layout/mixin/commonMixin";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import StockEquipmentStd from "@/api/v2/StockManage/StockEquipmentStd";

export default {
  name: "EquipmentWorkRegistration",
  mixins : [CommonMixin, MessagePopupDialogMixin],
  components: {WorkCodeRegistrationPopup, ejsGridWrapper, ErpButton,},
  data() {
    return {
      isPopupOpen: false,
      levelDiv: null,
      gridField: {
        title: "작업 부문/지역",
        count : 0,
        dataSource: [],
      },
      gridField2: {
        title: "작업목록",
        count : 0,
        dataSource: [],
      },
      eqpWorkDetail:{
        parentCode: null,
        listD:null,
        listU:null,
        listI:null,
      }

    };
  },
  created() {
  },
  mounted() {
    this.getEqpWorkList();
  },
  computed: {
    isPopupOpened() {
      return (this.isPopupOpen);
    },
    initGrid(){
      return{
        columns : [
          { field: "workTreeName", textAlign: 'left',   allowEditing: false, width: 200, visible: true,  type: "string",  headerText: "작업 부문/지역", },
          { field: "workCode",     textAlign: 'center', allowEditing: false, width: 100, visible: true,  type: "string",  headerText: "코드", },
          { field: "levelDiv",     textAlign: 'center', allowEditing: false, width: 80,  visible: false, type: "string",  headerText: "레벨구분", },
          { field: "sortNo",       textAlign: 'center', allowEditing: false, width: 80,  visible: true,  type: "string",  headerText: "정렬", },
          { field: "useFlag",      textAlign: 'center', allowEditing: false, width: 80,  visible: true,  type: "boolean",  headerText: "사용", displayAsCheckBox: true, editType: "booleanedit"},
        ],
        provides: [Filter, Resize, Page, ForeignKey, ExcelExport, Edit,],
        dataSource: this.gridField.dataSource,
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        isSelectedRowRetain: true,
        isShowProgress: true,
        isNOColumnDisplay:false,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        noColumnWidth: 20,
        allowPaging: false,
        pageSettings: { pageSize: 50 },

      };
    },

    initGrid2(){
      return{
        columns : [
          { field: "workCode", textAlign: 'center', allowEditing: false, width: 100,  visible: true, type: "string",  headerText: "코드",  isPrimaryKey: true,},
          { field: "workName", textAlign: 'center', allowEditing: true,  width: 200,  visible: true, type: "string",  headerText: "작업명", },
          { field: "sortNo",   textAlign: 'center', allowEditing: true,  width: 100,  visible: true, type: "number",  headerText: "정렬", isNumericType: true, inputNumberProperty: { maxLength: 3, allowDot: false, allowMinus: false }, },
          { field: "remarks",  textAlign: 'center', allowEditing: true,  width: 250,  visible: true, type: "string",  headerText: "비고",  isRemarks: true,},
        ],
        provides: [Filter, Resize, Page, ForeignKey, ExcelExport, Edit,],
        dataSource: this.gridField2.dataSource,
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        isSelectedRowRetain: false,
        isShowProgress: false,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        noColumnWidth: 35,
        allowPaging: true,
        pageSettings: { pageSize: 50 },
        validationRules: {
          workName:  {required: true,},
          sortNo: {required: true, duplicateCheck: true, min:0},
        },
      };
    },

  },
  methods: {
    //region #################################### API ############################################
    //작업지역,부문조회
    async getEqpWorkList(){
      this.gridField.dataSource = await StockEquipmentStd.getEqpWorkList();
    },
    //작업 리스트 조회(3레벨고정)
    async getEqpWorkDetail(rowData){
      const args ={
        parentCode : rowData.workCode,
        levelDiv : 3
      };
      this.gridField2.dataSource = await StockEquipmentStd.getEqpWorkList(args);
    },
    async saveEqpWorkDetail(args){
      this.gridField2.dataSource = await StockEquipmentStd.saveEqpWorkList(args);
    },
    //endregion
    //region #################################### 버튼 이벤트 ####################################
    onClickModifyParent(){
      if(this.$refs.grid.getSelectedRecords().length===0){
        this.infoToast("수정할 셀을 선택해 주세요.");
        return;
      }
      this.isPopupOpen = true;
      this.$nextTick(  () => {
        this.$refs.codePopup.initShow(false,  this.$refs.grid.getSelectedRecords()[0]);
      });
    },
    onClickAddParent(){
      this.isPopupOpen = true;
      this.$nextTick(  () => {
        this.$refs.codePopup.initShow(true, null);
      });
    },
    onClickAddRow(){
      if(this.eqpWorkDetail.parentCode==null){
        this.errorToast("작업지역을 선택해 주세요");
        return;
      }

      if(this.levelDiv != "2"){
        this.errorToast("상위분류에는 작업목록을 등록 할 수 없습니다.");
        return;
      }

      this.$refs.grid2.addRecord({
        workCode: "",
        parentCode: this.eqpWorkDetail.parentCode,
        workName: "",
        sortNo: "",
        useFlag: true,
      });
    },
    onClickDelRow(){
      return this.$refs["grid2"].deleteRecord();
    },
   async onClickSave(){
      if (!this.$refs.grid2.validate()) {
        return;
      }
      const {addedRecords, changedRecords, deletedRecords,} = this.$refs.grid2.getBatchChanges();
      this.eqpWorkDetail.listD = deletedRecords;
      this.eqpWorkDetail.listU = changedRecords;
      this.eqpWorkDetail.listI = addedRecords;
      await this.saveEqpWorkDetail(this.eqpWorkDetail);
      this.infoToast(this.$t('main.popupMessage.saved'));
      const rowData ={
        workCode : this.eqpWorkDetail.parentCode,
      };
      await this.getEqpWorkDetail(rowData);
    },
    onClickExcel(){
      this.$refs.grid2.excelExport({fileName: this.gridField2.title+".xlsx"});
    },
    //endregion
    //region #################################### 팝업 관련 #############################################
    closePopup(){
      this.isPopupOpen = false;
    },
    savePopup(){
      this.isPopupOpen = false;
      this.getEqpWorkList();
    },
    //endregion
    //region #################################### 그리드 관련 #############################################
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onHeaderCellInfo2(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid2.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    //그리드 셀 스타일 적용
    onQueryCellInfo(args) {
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    onQueryCellInfo2(args) {
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    //그리드1 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    gridComplete2(){
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
    //그리드 클릭
    onGridClick(args){
      console.log(args.rowData.levelDiv);
      //if(args.rowData.levelDiv==2) {
        this.getEqpWorkDetail(args.rowData);
        this.gridField2.title = "작업목록 ["+args.rowData.pathDesc+"]";
      //}
    },
    //endregion
  }

};
</script>
<style scoped>
body .article-left {
  width: 25%;
}
body .article-right {
  width: 75%;
}
</style>