<template>
  <div>
    <ejs-dialog
        ref="dialog"
        header="작업지역 등록"
        width="500"
        height="auto"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :cssClass="
        isNew ? $t('className.popup.productCodePopupAdd') : $t('className.popup.productCodePopupModify')"
        @close="onClickPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class = "content-body">
              <article class="body-article">
                <div class="article-left" style="width: 100%">
                  <section class="article-section">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">기본 정보</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <!-- detail field -->
                              <li class="field voc-section">
                                <!-- 필수 : required -->
                                <div class="title">상위분류명</div>
                                <div class="content" >
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <ejs-dropdownlist ref="parentCode" v-model="popupInputField.parentCode"
                                                          :readonly="popupInputField.hasChild > 0"
                                                          :dataSource="popupInputFieldOptions.parentCode"
                                                          :fields="popupInputFieldOptions.fields"
                                                          cssClass="body-data-dropdown"/>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field voc-section">
                                <!-- 필수 : required -->
                                <div class="title required">부문/지역</div>
                                <div class="content" >
                                  <ul class="row"  >
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-text id="classCodeName" ref="classCodeName" v-model="popupInputField.workName"/>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>

                              <li class="field voc-section">
                                <div class="title">코드</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-text id="classCode" ref="classCode" v-model="popupInputField.workCode" disabled/>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field voc-section">
                                <div class="title">사용 여부</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input type="checkbox" id="useFlag" ref="useFlag" v-model="popupInputField.useFlag"/><i/>
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field voc-section">
                                <div class="title">정렬</div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-number id="sortNo" ref="sortNo" v-model="popupInputField.sortNo" />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </article>
            </div>
            <div class="windowFooter">
              <ul class="button">
                <li class="create keyColor">
                  <erp-button button-div="SAVE" :is-shortcut-button="true" :is-key-color="true" @click.native="onClickSave">저장</erp-button>
                </li>
                <li class="close">
                  <erp-button button-div="CLOSE" @click.native="onClickPopupClose">닫기</erp-button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import InputText from "@/components/common/text/InputText.vue";
import {validateFormRefs} from '@/utils/formUtil';
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputNumber from "@/components/common/InputNumber.vue";
import StockEquipmentStd from "@/api/v2/StockManage/StockEquipmentStd";


export default {
  name:'WorkCodeRegistrationPopup',
  mixins : [commonMixin, MessagePopupDialogMixin],
  components:{InputNumber, InputText, ErpButton,},
  data(){
    return{
      showTreePopup: false,
      popupInputField : {
        workCode : null,
        parentCode: null,
        workName: null,
        levelDiv: null,
        sortNo: null,
        remarks: null,
        useFlag: 1,
        hasChild: 0
      },
      popupInputFieldOptions: {
        parentCode: null,
        fields: { text: "workName", value: "workCode" },
      },
      //validate 필수 체크  항목
      validateRefList: {
        // classCode: { required: true }, // :disabled="!isNew"
        classCodeName: { required: true },
      },

      isNew: true,

      commonField:{
        division: [],
      },
    };
  },
  async mounted(){
  },

  computed: {
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },

  methods: {
    //필수입력체크
    validateFormRefs,
    // #####################################  버튼  ########################################
    // 팝업 저장 버튼
    async onClickSave() {
      if (!this.validate) {
        return;
      }
      //부모가 최상위면 1레벨 아니면 2레벨
      if(this.popupInputField.parentCode ==="0"){
        this.popupInputField.levelDiv = 1;
      }else{
        this.popupInputField.levelDiv = 2;
      }
      if(this.isNew){
         await StockEquipmentStd.postEqpWorkList(this.popupInputField);
      } else{
         await StockEquipmentStd.patchEqpWorkList(this.popupInputField);
      }
      this.infoToast(this.$t('main.popupMessage.saved'));
      this.$emit('popupSaved');
    },
    //####################### 부모화면 function ###############################
    async initShow(isNew,item){

      this.popupInputFieldOptions.parentCode = await StockEquipmentStd.getEqpWorkList({parentCode:0});
      this.popupInputFieldOptions.parentCode.unshift({ workCode: "0", workName: "최상위" });
      this.isNew = isNew;
      if(!isNew){
        this.popupInputField = {...item};
      }
    },
    //팝업 닫기 버튼
    onClickPopupClose(){
      this.$emit('popupClosed');
    },
    // ######################## 자식화면 function ##################################
    // #####################################  공통  ########################################
    // #####################################  그리드  ########################################

  }


};
</script>
<style scoped>

.voc-section {
  width: 100% !important;
}
</style>