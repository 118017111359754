import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-equipment-std';

class StockEquipmentStd {

    /** 장비 리스트 조회 */
    async getEqpList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/eqp/list` ,{params} );
        return data;
    }
    /** 장비 상세 조회 */
    async getEqpDetail(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/eqp/detail` ,{params} );
        return data;
    }
    /** 장비 등록 */
    async postEqpDetail(params) {
        const {data} = await GolfErpAPI.http.post(`${ROOT_PATH}/eqp/detail` ,params );
        return data;
    }
    /** 장비 수정 */
    async patchEqpDetail(params) {
        const {data} = await GolfErpAPI.http.patch(`${ROOT_PATH}/eqp/detail` ,params );
        return data;
    }
    /** 장비 스펙 조회 */
    async getEqpSpecList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/eqp/spec` ,{params} );
        return data;
    }
    /** 장비 수리이력 조회 */
    async getEqpRepairList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/eqp/repair` ,{params} );
        return data;
    }


    /** 작업 지역/부문/작업리스트 조회 */
    async getEqpWorkList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/work/list` ,{params} );
        return data;
    }
    /** 작업 지역/부문 등록 */
    async postEqpWorkList(params) {
        const {data} = await GolfErpAPI.http.post(`${ROOT_PATH}/work/list` , params );
        return data;
    }
    /** 작업 지역/부문 수정 */
    async patchEqpWorkList(params) {
        const {data} = await GolfErpAPI.http.patch(`${ROOT_PATH}/work/list` , params );
        return data;
    }
    /** 작업 작업리스트 등록,수정 */
    async saveEqpWorkList(params) {
        const {data} = await GolfErpAPI.http.put(`${ROOT_PATH}/work/list` , params );
        return data;
    }

}
export default new StockEquipmentStd();
